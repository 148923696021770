import React from "react";
import PageContainer from "../../components/PageContainer/PageContainer";
import thankyou from "../../assets/images/thank-you.svg";

const ThankYouPage = () => {
  return (
    <PageContainer>
      <img src={thankyou} className="status-image" alt="thank-you" />
      <p className="status-message">Payment completed. Please check console for return url</p>
    </PageContainer>
  );
};

export default ThankYouPage;
