import axios, {AxiosPromise, Method, AxiosRequestConfig} from "axios";
import rollbar from "../utilities/error-logger";

export interface ICallApi extends AxiosRequestConfig {
    url: string;
    method: Method;
    headers?: Record<string, unknown>;
    data?: unknown;
    params?: Record<string, unknown>;
    timeout?: 30000;
}

class ApiService {
    CallApi<T>({
                   url,
                   method,
                   headers,
                   data,
                   params,
                   timeout,
               }: ICallApi): AxiosPromise<T> {
        const config: AxiosRequestConfig = {
            method,
            data,
            params,
            url,
            timeout,
            headers: {
                Accept: "application/json",
                client_id: process.env.API_CLIENT_ID,
                ...headers,
            },
        };

        config.headers['client_id'] = process.env.API_CLIENT_ID;
        config.headers['Content-Type'] = `application/json`;
        try {
            return axios.request(config);
        } catch (error) {
            rollbar.error(`Error calling api `, error);
        }

    }
}

export default new ApiService();
