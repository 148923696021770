import React, {FC} from "react";
import {LiveAnnouncer} from "react-aria-live";

import Router from "../Router/Router";

const RootPage: FC = () => {
    return (
        <LiveAnnouncer>
            <Router/>
        </LiveAnnouncer>
    );
};

export default RootPage;
