import React, { FC } from "react";
import { QueryClientProvider } from "react-query";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";

import ReactQueryClient from "./ReactQueryClient";
import RootPage from "../../pages/RootPage/RootPage";
import store, { history } from "../../state/store";

const App: FC = () => (
  <QueryClientProvider client={ReactQueryClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <RootPage />
      </ConnectedRouter>
    </Provider>
  </QueryClientProvider>
);

export default App;
