import {disableStoredPayment} from "../api/payments";

export const { API_BASE_URL } = process.env;

export default {
  endpoints: {
    paymentGateway: {
      paymentSessions: `${API_BASE_URL}/public/gateways/ADYEN/sessions`,
      getReturnUrl: `${API_BASE_URL}/public/gateways/ADYEN/return`,
      disableStoredPayment: `${API_BASE_URL}/public/gateways/ADYEN/recurring/disable`,
      submitPayment: `${API_BASE_URL}/public/gateways/ADYEN/payments`,
      paymentDetails: `${API_BASE_URL}/public/gateways/ADYEN/payments/details`,
     },
  },
  timeout: {
    greetingsTimeout: 2000,
    reviewUserTimeout: 20000,
    appTimeout: 1800000,
  },
  reactQueryConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retry: false,
      },
    },
  },
};
