import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";

import ROUTES from "../../constants/routes";

import PaymentPage from "../PaymentPage/PaymentPage";
import ThankYouPage from "../ThankYouPage/ThankYouPage";

interface Page {
  path: string;
  component: FC;
  exact: boolean;
}

const publicPages: Array<Page> = [
  {
    path: ROUTES.landingPage,
    component: PaymentPage,
    exact: true,
  },
  {
    path: ROUTES.thankYouLocal,
    component: ThankYouPage,
    exact: true,
  }
];


const Router: FC = () => (
  // TODO - limit private pages access
  <>
    <Switch>
      {publicPages.map(({ path, component, exact }) => {
        return <Route key={path} path={path} component={component} exact={exact} />;
      })}
    </Switch>
  </>
);

export default Router;
