export default {
    defaultAlertMessage: "If you are paying by credit or debit card from outside the UK, your card must be enabled to make international payments (GBP). Please contact your card provider to confirm that this has been done before making your payment.",
    rtaAlertMessage1: "You are entering your debit or credit card details to be securely saved on Adyen Smartpay site to be used to pay your Annual Subscription payment each year. If you are paying by debit or credit card from outside the UK, your card must be enabled to make international payments (GBP). Please contact your card provider to confirm that this has been done before making your payment.",
    rtaAlertMessage2: "You are selecting to set up your debit or credit card to set up a repeat payment for your Annual Subscription invoice. Just register your card here, we will automatically take your payment from it each year as your Annual Subscription invoice is raised.",
    rtaAlertMessage3: "Each year we will communicate your invoice 30 days in advance of the payment being taken. You will be able to log in and review this and confirm you are happy for this to continue or not. You will be able to update this up to 3 working days before the payment is due.\n" +
        "Please ensure you have funds on your debit or credit card, if payment is not successful you will be required to make a manual payment via myACCA. We will be in contact if this occurs via the registered email on your account.",
    storedPaymentAlertMessage: "Please select your Payment Method <br/>" +
        "Pay using previously stored details below (where applicable)<br/>" +
        "Or pay using one of the payment methods listed further below<br/>",
    unionPayAlertMessage: "UnionPay payment method is supported in the following countries: <br/><br/>" +
        "Antigua and Barbuda, Australia, Cambodia, Canada, Hong Kong SAR of China, Indonesia, Japan, Kazakhstan, Kenya, Republic of Korea, Laos, Macau SAR of China, Mainland China, Malaysia, Mongolia, Myanmar, Philippines, Singapore, Suriname, Thailand, UAE, United Kingdom, United States, Uzbekistan and Vietnam. <br/><br/>" +
        "If your address on myACCA is a country <b style='color:red'>not</b> on this list, please <b>do not select</b> this option and pay by another method shown on screen.  <br/>",
    cancelCtaText: "Cancel payment",
    cancelCtaRtaText: "Previous",
};
