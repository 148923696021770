import React, {FC} from "react";
import {Logo, Alert, Button, IconPdf32, IconContactPhone24} from "acca-design-system";
import "./page-container.scss";

const PageContainer: FC = ({children}) => {
    return (
        <div className="payment-gateway">
            <header className="payment-gateway__header">
                <Logo className="payment-gateway__logo"/>
                <div className="payment-gateway__heading">ACCA Payment</div>
            </header>
            <div className="payment-container u-content-container-medium u-content-center u-margin-top-4 u-margin-bottom-4">
                <main>{children}</main>
            </div>
            <footer className="u-bg-white">
                <section id="footer-middle" className="footer-row footer-row--bottom">
                    <div className="grid-container fluid">
                        <div className="grid-x grid-margin-x">
                            <div className="footer-section cell">
                                <div className="footer-logo">
                                    <a
                                        href="https://www.accaglobal.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-logo__link"
                                        title="Home Link"
                                    >
                                        <Logo className="footer-logo__image"/>
                                    </a>
                                </div>
                                <ul className="footer__social-links">
                                    <li className="footer__social-link">
                                        <Button
                                            icon={IconContactPhone24}
                                            to="tel:+44 0141 582 2000"
                                            text="Call us"
                                            variant="nav"
                                        />
                                        <br/><div className="u-grey-copy">+44 (0)141 582 2000</div>
                                    </li>
                                    <li className="footer__social-link">
                                        <Button
                                            icon={IconPdf32}
                                            to="https://www.accaglobal.com/gb/en/footer-toolbar/contact-us.html"
                                            text="Contact us"
                                            variant="nav"
                                        />
                                        <br/><div className="u-text-small"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </div>
    );
};

export default PageContainer;
