import { History, createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware as createRouterMiddleware } from "connected-react-router";

import { rootReducer } from "./rootReducer";
import rootSaga from "./rootSaga";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const history: History = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(history);
const enhancer = composeEnhancer(applyMiddleware(routerMiddleware, sagaMiddleware));

const initialState = {};

const configureStore = () => {
  const store = createStore(rootReducer(history), initialState, enhancer);

  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore();
